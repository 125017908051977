import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";
import {
  getLocalStorageItems,
  updateLocalStorageItems,
} from "../../utils/localStorageUtils";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL + "/cultzyme-api/v1",
  prepareHeaders: (headers, { getState }) => {
    // const accessToken = getState().auth.token;
    const accessToken = getLocalStorageItems("accessToken");
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    if (isRefreshing) {
      headers.delete("authorization");
    }

    return headers;
  },
});

let isRefreshing = false; // Bandera para saber si se está refrescando el token
let refreshPromise = null; // Promesa compartida para solicitudes pendientes
const pendingRequests = []; // Cola de solicitudes pendientes

const addPendingRequest = (request) =>
  new Promise((resolve, reject) => {
    pendingRequests.push({ resolve, reject, request });
  });

const processPendingRequests = (newAccessToken) => {
  pendingRequests.forEach(({ resolve, request }) => {
    // Modificar la solicitud original con el nuevo token
    if (typeof request.args === "string") {
      request.args = { url: request.args };
    }
    request.args.headers = request.args.headers || new Headers();
    request.args.headers.set("authorization", `Bearer ${newAccessToken}`);

    resolve(request); // Reintentar la solicitud
  });
  pendingRequests.length = 0; // Vaciar la cola
};

const failPendingRequests = (error) => {
  pendingRequests.forEach(({ reject }) => reject(error));
  pendingRequests.length = 0; // Vaciar la cola
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const refreshToken = getLocalStorageItems("refreshToken");

    if (refreshToken) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = baseQuery(
          {
            url: "/auth/refreshtoken",
            method: "POST",
            headers: {
              refreshtoken: refreshToken,
            },
            body: { userName: api.getState().auth.user?.userName },
          },
          api,
          extraOptions
        )
          .then((refreshResult) => {
            if (refreshResult?.data) {
              const newAccessToken = refreshResult.data.accessToken;
              const newRefreshToken = refreshResult.data.refreshToken;

              api.dispatch(
                setCredentials({
                  accessToken: newAccessToken,
                  refreshToken: newRefreshToken,
                  userName: api.getState().auth.user.userName,
                })
              );

              updateLocalStorageItems("accessToken", newAccessToken);
              updateLocalStorageItems("refreshToken", newRefreshToken);

              processPendingRequests(newAccessToken);
              return newAccessToken;
            } else {
              api.dispatch(logOut());
              failPendingRequests(new Error("Refresh token failed"));
              throw new Error("Refresh token failed");
            }
          })
          .catch((error) => {
            console.error("Error al refrescar el token:", error);
            api.dispatch(logOut());
            failPendingRequests(error);
            throw error;
          })
          .finally(() => {
            isRefreshing = false;
            refreshPromise = null;
          });
      }

      try {
        await addPendingRequest({ args, api, extraOptions });
        const newAccessToken = await refreshPromise;

        if (typeof args === "string") {
          args = { url: args };
        }
        args.headers = args.headers || new Headers();
        args.headers.set("authorization", `Bearer ${newAccessToken}`);
        result = await baseQuery(args, api, extraOptions);
      } catch (error) {
        console.error("No se pudo refrescar el token.");
        throw error;
      }
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiOne",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Process"],
  endpoints: (builder) => ({
    getData: builder.query({
      query: (endpoint) => ({
        url: endpoint,
        method: "GET",
      }),
    }),
    postData: builder.mutation({
      query: ({ endpoint, body }) => ({
        url: endpoint,
        method: "POST",
        body: body,
      }),
    }),
    updateData: builder.mutation({
      query: ({ endpoint, body }) => ({
        url: endpoint,
        method: "PUT",
        body: body,
      }),
    }),
    deleteData: builder.mutation({
      query: (endpoint) => ({
        url: endpoint,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetDataQuery,
  usePostDataMutation,
  useUpdateDataMutation,
  useDeleteDataMutation,
} = apiSlice;
