import React, { useState, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { useCreateProcessMutation } from "../../../features/process/processApiSlice";
import { useUpdateCalibrationMutation } from "../../../features/bion/bionApiSlice";
import { SiFuturelearn as BionicIcon } from "react-icons/si";
import { FaCopy as ReplicationIcon } from "react-icons/fa";
import { IoCreateSharp as CreationIcon } from "react-icons/io5";
import { CgCalibrate as CalibrationIcon } from "react-icons/cg";
import { ChevronDown } from "lucide-react";
import BionImg from "../../../assets/images/bion.png";
import CalibrateModal from "../CalibrateModal/CalibrateModal";
import { Button } from "../../UI/Shadcn/Button";
import { Card, CardContent } from "../../UI/Shadcn/Card";
import ProcessPlanner from "./ProcessPlanner/ProcessPlanner";
import ProcessPlannerOld from "./ProcessPlannerOld/ProcessPlannerOld";

const CardButton = ({
  icon: Icon,
  title,
  description,
  onClick,
  disabled,
  comingSoon,
}) => (
  <Card className="overflow-hidden transition-all duration-300 hover:shadow-lg group h-full">
    <CardContent className="p-0 h-full">
      <Button
        onClick={onClick}
        disabled={disabled}
        className="w-full h-full flex flex-col items-center justify-between text-left p-6 bg-card hover:bg-primary hover:text-primary-foreground transition-colors duration-300 hover:shadow-xl border-2  border-primary"
      >
        <Icon className="text-5xl mb-4 text-primary group-hover:text-primary-foreground" />
        <div className="w-full flex-grow flex flex-col justify-center mb-4">
          <p className="text-sm whitespace-normal">{description}</p>
        </div>
        <span className="text-xl font-bold">{title}</span>
        {/* {comingSoon && (
          <span className="absolute top-2 right-2 text-xs bg-green-500 text-white px-2 py-1 rounded-full">
            Coming soon!
          </span>
        )} */}
      </Button>
    </CardContent>
  </Card>
);

const CreateProcess = ({ bion }) => {
  const [creationIsOpen, setCreationIsOpen] = useState(false);
  const [creationOldIsOpen, setCreationOldIsOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [promptMode, setPromptMode] = useState(null);
  const [calibrationIsOpen, setCalibrationIsOpen] = useState(
    bion?.bionMode === "CALIBRATION_ACTIVE"
  );

  const [updateCalibrationReq] = useUpdateCalibrationMutation();
  const [selectedType, setSelectedType] = useState();
  const processNameRef = useRef();
  const descriptionRef = useRef();
  const [createProcessReq, { isLoading: createProcessReqIsLoading }] =
    useCreateProcessMutation();

  const toggleCreation = () => setCreationIsOpen((prevState) => !prevState);
  const toggleCreationOld = () =>
    setCreationOldIsOpen((prevState) => !prevState);

  const closeCalibration = (deactivateCalibration = true) => {
    setCalibrationIsOpen(false);
    if (!deactivateCalibration) return;
    updateCalibrationReq({
      bionId: bion.bionId,
      calibrationActive: false,
      sensorType: "PH",
    });
  };

  const openCalibration = (event) => {
    event.preventDefault();
    setCalibrationIsOpen(true);
  };

  const createProcessHandler = () => {
    createProcessReq({
      bionId: bion.bionId,
      processName: processNameRef.current.value,
      description: descriptionRef.current.value,
      processType: selectedType,
      endDate: endDate ? endDate.$d.toISOString() : null,
    });
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    createProcessHandler();
  };

  const isDateMoreThan30DaysAgo = (date) => {
    return false;
    if (!date) return true;
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return new Date(date) < thirtyDaysAgo;
  };

  const promptBtnClickHandler = (title) => setPromptMode(title);

  return (
    <div className="p-6 bg-background text-foreground">
      <AnimatePresence>
        {calibrationIsOpen && (
          <CalibrateModal
            onClose={closeCalibration}
            bion={bion}
            createProcess={createProcessHandler}
          />
        )}
      </AnimatePresence>
      {creationIsOpen && <ProcessPlanner bion={bion} />}
      {creationOldIsOpen && <ProcessPlannerOld bion={bion} />}
      {!creationIsOpen & !creationOldIsOpen ? (
        <>
          <header className="mb-8">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-3xl font-bold flex items-center gap-4">
                <img src={BionImg} alt="Bion" className="w-10 h-10" />
                {bion.bionName}
              </h1>
              <Button
                onClick={openCalibration}
                variant="outline"
                className="text-base bg-background hover:bg-primary transition-colors duration-300 px-4 py-2 h-[48px] rounded-md shadow-sm border border-primary relative flex items-center"
              >
                <div className="flex flex-col items-start mr-4">
                  <span className="font-semibold text-foreground hover:text-background">
                    Sensor Calibration
                  </span>
                  <span className="text-xs text-foreground">
                    Last time:{" "}
                    {bion?.lastPhCalibrationDate
                      ? new Date(bion.lastPhCalibrationDate).toDateString()
                      : "Never"}
                  </span>
                </div>
                <CalibrationIcon className="w-5 h-5 mr-2 text-blue-600" />
                <ChevronDown className="w-4 h-4 text-gray-400" />
              </Button>
            </div>
            <h2 className="text-xl font-semibold text-primary mb-4">
              Welcome to the BION Process Creation Page
            </h2>
            <ul className="list-disc list-inside text-sm text-muted-foreground space-y-1">
              <li>
                <span className="font-medium">
                  Design and tailor operational processes
                </span>{" "}
                for your specific bioreactor.
              </li>
              <li>
                Choose from three distinct pathways:
                <ul className="list-decimal list-inside pl-4 space-y-1">
                  <li>
                    <span className="font-medium">Craft a custom process</span>{" "}
                    to meet your unique requirements.
                  </li>
                  <li>
                    <span className="font-medium">
                      Replicate an existing one
                    </span>{" "}
                    for efficiency.
                  </li>
                  <li>
                    <span className="font-medium">
                      Dive into the innovative BIONIC mode
                    </span>{" "}
                    for cutting-edge configurations.
                  </li>
                </ul>
              </li>
              <li>
                Define the parameters that drive your bioreactor's performance
                through this intuitive interface.
              </li>
              <li>
                Whether you're a precision enthusiast or prefer the convenience
                of proven methods, this page gives you full control of
                bioprocessing.
              </li>
              <li>Ensure your BION operates in harmony with your vision.</li>
            </ul>
          </header>

          <div
            className={`grid grid-cols-1 md:grid-cols-3 gap-6 ${
              !bion.isLidSet ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            <CardButton
              icon={CreationIcon}
              title="Creation"
              description="Unleash your creativity! Design a bespoke process tailored to your specific needs. Define parameters, set conditions, and sculpt a unique path for your BION."
              onClick={toggleCreation}
              disabled={isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)}
            />

            <CardButton
              icon={CreationIcon}
              title="Creation Old"
              description="Old Creation"
              onClick={toggleCreationOld}
              disabled={isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)}
            />

            <CardButton
              icon={ReplicationIcon}
              title="Replication"
              description="Efficiency at your fingertips! Replicate a proven process with a click. Clone and adapt successful configurations for a seamless and reliable operation."
              onClick={() => {
                if (!isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)) {
                  window.location.href = `/files?bionId=${bion.bionId}`;
                }
              }}
              disabled={isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)}
            />
            <CardButton
              icon={BionicIcon}
              title="Bionic"
              description="Experience innovation! Step into BIONIC mode to explore cutting-edge configurations. Embrace automated intelligence and let your BION adapt dynamically to optimize performance."
              onClick={() => {}}
              comingSoon={true}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CreateProcess;
