import DefineBioprocess from "./DefineBioprocess/DefineBioprocess";


const basePromptDataOld = `I would like to create a process with the following details:
Process Name: {input:processName}.
Process Type: {select:processType}.  
Description: {textArea:description}.  
End Date (Optional): {datePicker:endDate}`
const basePromptOptionsOld = {
  processType: [
    {valueBackend: 'CG', value: 'Cell growth', label: 'Cell growth'},
    {valueBackend: 'PE', value: 'Precision fermentation', label: 'Precision fermentation'}
  ],  
}


export default function ProcessPlannerOld({bion}) {
  const bionId = bion.bionId;

  return (
    <div className="page">
      <div className="accordion__container">
            <DefineBioprocess
              bioprocessDefinitionPrompt={basePromptDataOld}
              options={basePromptOptionsOld}
              bionId={bionId}
            />
      </div>
    </div>
  );
}
