import { apiSlice } from "../../app/api/apiSlice";
import pusher from "../../app/pusher";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Endpoint for signing out the user
    signoutUser: build.mutation({
      query: () => ({
        url: "/auth/signout",
        method: "PUT",
      }),
    }),

    //  Endpoint to confirm the user's password retrieval process
    confirmPasswordRetrieval: build.mutation({
      query: (data) => ({
        url: "/auth/password-retrievals/confirm",
        method: "PUT",
        body: data,
      }),
    }),

    // Endpoint to confirm the user's account after sign-up
    confirmUserAccount: build.mutation({
      query: (data) => ({
        url: "/auth/confirm-account",
        method: "PUT",
        body: data,
      }),
    }),

    // Endpoint to unlink an existing Cultzyme account from the given BION
    unlinkAccounts: build.mutation({
      query: (data) => ({
        url: "/auth/unlink-accounts",
        method: "POST",
        body: data,
      }),
    }),

    // Endpoint for signing up a new user
    signupUser: build.mutation({
      query: (userData) => ({
        url: "/auth/signup-machine",
        method: "POST",
        body: userData,
      }),
    }),

    signupUserWithoutBion: build.mutation({
      query: (userData) => ({
        url: "/auth/signup",
        method: "POST",
        body: userData,
      }),
    }),

    // Endpoint for general user sign-in
    signinUser: build.mutation({
      query: (userCredentials) => ({
        url: "/auth/signin",
        method: "POST",
        body: userCredentials,
      }),
    }),

    // Endpoint for the user to log into the app and link to the given BION, allowing the user to use it
    signinMachine: build.mutation({
      query: (data) => ({
        url: "/auth/signin-machine",
        method: "POST",
        body: data,
      }),
    }),

    // Endpoint for either approving or rejecting a new user registration request
    handleNewUserRegistration: build.mutation({
      query: ({ action, data }) => ({
        url: `/auth/${action}-accounts`,
        method: "POST",
        body: data,
      }),
    }),

    // Endpoint to reject a linking request to the given BION
    rejectLinkingAccounts: build.mutation({
      query: (data) => ({
        url: "/auth/link-reject-accounts",
        method: "POST",
        body: data,
      }),
    }),

    // Endpoint to approve a linking request to the given BION
    approveLinkingAccounts: build.mutation({
      query: (data) => ({
        url: "/auth/link-approve-accounts",
        method: "POST",
        body: data,
      }),
    }),

    // Endpoint to link an existing Cultzyme account to a BION
    linkAccounts: build.mutation({
      query: (data) => ({
        url: "/auth/link-accounts",
        method: "POST",
        body: data,
      }),
    }),

    // Endpoint para obtener el nombre de un BION basado en su ID
    getBionName: build.query({
      query: (bionId) => `/bion/${bionId}`,
    }),

    // Retrieve user data after signing up
    getSignedUpUserData: build.query({
      query: (confirmationToken) => `/auth/signup-details/${confirmationToken}`,
    }),

    // Endpoint to resend the verification code to the user when creating and verifying their account
    requestVerificationCode: build.query({
      query: (userName) => `/auth/request-verification-code/${userName}`,
    }),

    // Endpoint for retrieving the password of the user based on their username or email
    forgetPassword: build.query({
      query: (userNameOrEmail) => `/auth/forget-password/${userNameOrEmail}`,
    }),

    
    getUserAccountApproved: build.query({
      queryFn: () => ({ data: {} }),
      async onCacheEntryAdded(
        userName,
        { updateCachedData, cacheEntryRemoved, getState }
      ) {
        pusher.initializePusher();

        pusher.subscribeToChannels(
          {
            channelName: `private-user-approve-reject-status-channel-${userName}`,
            eventName: "user-approve-reject-status-changed",
          },
          ({ message, approved }) => {
            updateCachedData((draft) => {
              draft.approved = approved;
              draft.message = message;
            });
          }
        );

        await cacheEntryRemoved;

        pusher.unsubscribeFromChannels(
          `private-user-approve-reject-status-channel-${userName}`
        );
      },
    }),
  }),
});

export const {
  useSigninUserMutation,
  useSignupUserMutation,
  useSignupUserWithoutBionMutation,
  useConfirmUserAccountMutation,
  useLazyRequestVerificationCodeQuery,
  useConfirmPasswordRetrievalMutation,
  useHandleNewUserRegistrationMutation,
  useGetSignedUpUserDataQuery,
  useLazyForgetPasswordQuery,
  useGetUserAccountApprovedQuery,
  useGetBionNameQuery,
} = authApiSlice;
