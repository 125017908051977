//src/components/Bion/CreateProcess/ProcessPlanner/DefineBioprocess/DefineBioprocess.jsx
import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../UI/Shadcn/Accordion';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../UI/Shadcn/Select';
import { Input } from '../../../../UI/Shadcn/Input';
import { VscServerProcess as BioprocessDefinitionIcon } from 'react-icons/vsc';
import { FaCheckCircle as CompletedIcon } from 'react-icons/fa';

const getOptions = (options, name) => {
  return options[name];
};

const DefineBioprocess = ({
  isExpanded,
  bioprocessDefinitionPrompt,
  options,
}) => {
  const [selectedValues, setSelectedValues] = useState({});
  const numberOfPlaceholders = (
    bioprocessDefinitionPrompt.match(/\{[^{}]+\}/g) || []
  ).length;
  
  const allValuesPresent =
    numberOfPlaceholders === Object.keys(selectedValues).length &&
    Object.values(selectedValues).every(Boolean);

  const selectChangeHandler = (name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const renderPrompt = (str) => {
    const parts = str.split(/({[^{}]+})/);
    return parts.map((part, index) => {
      if (!part.match(/{([^{}]+)}/)) return <span key={index}>{part}</span>;

      const placeholder = part.replace(/[{}]/g, '');
      const [type, placeholderName] = placeholder.split(':');
      switch (type) {
        case 'select':
          return (
            <Select
              key={index}
              onValueChange={(value) =>
                selectChangeHandler(placeholderName, value)
              }
            >
              <SelectTrigger className="w-full sm:w-[180px] inline-flex border-2 border-primary bg-transparent text-primary">
                <SelectValue placeholder="Select..." />
              </SelectTrigger>
              <SelectContent className="bg-card text-card-foreground border-primary">
                {getOptions(options, placeholderName).map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    className="hover:bg-muted focus:bg-muted"
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          );
        case 'input':
          return (
            <Input
              key={index}
              className="w-full sm:w-[180px] inline-flex border-2 border-primary bg-transparent text-primary font-bold text-center focus:outline-none focus:ring-0 focus:border-primary transition-colors duration-200"
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={isExpanded ? 'item-1' : undefined}
      className="border-2 rounded-lg border-primary hover:bg-primary/5 bg-card text-card-foreground shadow-md"
    >
      <AccordionItem value="item-1" className="border-none">
        <AccordionTrigger className="hover:no-underline p-4 sm:p-6 group">
          <div className="flex flex-col  sm:flex-row items-start w-full">
            <div className="bg-primary/10 p-3 rounded-full flex-shrink-0 mb-4 sm:mb-0 sm:mr-6">
              <BioprocessDefinitionIcon className="text-3xl sm:text-4xl text-primary" />
            </div>
            <div className="flex-grow flex flex-col items-start justify-start">
              <h3 className="text-lg sm:text-xl font-semibold">
                Bioprocess Definition
              </h3>
              <p className="text-sm sm:text-base text-muted-foreground mt-1">
                Define essential parameters to initiate your bioprocess
              </p>
              <p className="flex items-center text-green-500 font-semibold mt-2 text-sm sm:text-base">
                <CompletedIcon className="mr-2" /> Completed
              </p>
            </div>
            <div className="mt-4 mr-4 sm:mt-0 sm:ml-auto sm:text-right flex-shrink-0 flex flex-col items-start sm:items-end">
              <p className="text-base sm:text-lg font-bold">All set!</p>
              <p className="text-sm sm:text-base text-muted-foreground mt-1">
                Modify if required
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-4 sm:px-6 py-4 bg-muted/50 rounded-b-lg">
          <div className="text-base sm:text-lg prompt leading-relaxed space-y-4">
            {renderPrompt(bioprocessDefinitionPrompt)}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default DefineBioprocess;
