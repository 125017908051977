"use client";

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "../../components/UI/Shadcn/Card";
import { Loader2, AlertCircle, Download, Eye, Beaker } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../components/UI/Shadcn/Alert";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/UI/Shadcn/Tabs";
import { Badge } from "../../components/UI/Shadcn/Badge";
import { ScrollArea } from "../../components/UI/Shadcn/ScrollArea";
import { Button } from "../../components/UI/Shadcn/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../components/UI/Shadcn/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/UI/Shadcn/Tooltip";
import PDFDropzone from "./TrainingComponents/PDFDropzone";
import ExperimentalDesigns from "./TrainingComponents/ExperimentalDesigns";
import Optimizations from "./TrainingComponents/Optimizations";
import ImpactFactorsManager from "./TrainingComponents/ImpactFactorsManager";
import { useToast } from "../../hooks/use-toast";
import {
  useGetTrainingQuery,
  useStartDesignMutation,
  useGenerateImpactFactorsMutation,
  useCreateExperimentalDesignsMutation,
  useGenerateExperimentalDesignsMutation,
} from "../../app/api/aiApiSlice";
import CreateChatButton from "./TrainingComponents/CreateChatButton";

const stateConfig = {
  SEARCH: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
    canUploadPDFs: false,
    showGenerateModal: true,
  },
  SEARCH_FAILED: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
    isViewOnly: false,
  },
  IMPACT_FACTOR: {
    canGenerateImpactFactors: true,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
  },
  GENERATE_EXPERIMENTS: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
    isModalOpen: true,
    isModalClosable: false,
  },
  GENERATE_EXPERIMENTS_FAILED: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
  },
  DESIGN: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: true,
    canViewOptimizations: false,
  },
};

const createModal = (title, description) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[5]">
      <Card className="max-w-[425px] mx-auto border shadow-md bg-background">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-4 my-4">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
            <p className="text-sm text-muted-foreground">{description}</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const createErrorModal = (
  title,
  description,
  isGeneratingImpactFactors,
  handleRetry
) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[5]">
      <Card className="max-w-[525px] mx-auto border shadow-md bg-background px-6  ">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription >
           {description}
          </CardDescription>
        </CardHeader>
        <CardContent className="flex items-center space-x-4 my-4">
      
          <AlertCircle className="h-8 w-8 text-destructive" />
          <CardDescription className="text-sm text-muted-foreground">
            This may be due to network connectivity or temporary server issues.
            A new attempt might resolve the problem.
          </CardDescription>
      
        </CardContent>
        <CardFooter>
          <Button
            onClick={handleRetry}
            disabled={isGeneratingImpactFactors}
            className="w-full"
          >
            {isGeneratingImpactFactors ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Retrying...
              </>
            ) : (
              "Retry"
            )}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default function TrainingView() {
  const { trainingDoeId } = useParams();
  const [pdfs, setPdfs] = useState([]);
  const [impactFactors, setImpactFactors] = useState(null);
  const [isImpactFactorsModalOpen, setIsImpactFactorsModalOpen] =
    useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isGeneratingModalOpen, setIsGeneratingModalOpen] = useState(false);
  const [isExperimentsFailedModalOpen, setIsExperimentsFailedModalOpen] =
    useState(false);
  const [activeTab, setActiveTab] = useState("training");
  const navigate = useNavigate();
  const [loadingImpactFactors, setLoadingImpactFactors] = useState(false);
  const [isSearchFailedModalOpen, setIsSearchFailedModalOpen] = useState(false);
  const accessToken = useSelector(selectCurrentToken);
  const { toast } = useToast();
  // console.log(impactFactors, "✅✅✈️");

  const {
    data: training,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetTrainingQuery(trainingDoeId);
  const [startDesign, { isLoading: isStartingDesign }] =
    useStartDesignMutation();
  const [generateImpactFactors, { isLoading: isGeneratingImpactFactors }] =
    useGenerateImpactFactorsMutation();
  const [
    createExperimentalDesigns,
    { isLoading: isCreatingExperimentalDesigns },
  ] = useCreateExperimentalDesignsMutation();
  const [
    generateExperimentalDesigns,
    { isLoading: isGeneratingExperimentalDesigns },
  ] = useGenerateExperimentalDesignsMutation();

  useEffect(() => {
    if (training) {
      setPdfs(
        training.pdfs.map(
          (pdf) =>
            new File([], pdf.fileName, {
              type: "application/pdf",
              lastModified: new Date(pdf.createdDate).getTime(),
            })
        )
      );

      const currentStateConfig =
        stateConfig[training.state] || stateConfig.SEARCH;

      if (currentStateConfig.canViewExperimentalDesigns) {
        setActiveTab("experimental");
      } else {
        setActiveTab("training");
      }

      if (training.state === "SEARCH_FAILED") {
        setIsSearchFailedModalOpen(true);
      }

      if (training.state === "GENERATE_EXPERIMENTS_FAILED") {
        setIsExperimentsFailedModalOpen(true);
      }

      if (training.state === "SEARCH" && currentStateConfig.showGenerateModal) {
        setIsGenerateModalOpen(true);
      }

      if (training && training.state === "IMPACT_FACTOR") {
        const fetchImpactFactors = async () => {
          setLoadingImpactFactors(true);
          try {
            const response = await fetch(
              `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/impact-factors/${trainingDoeId}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            if (!response.ok)
              throw new Error("Failed to fetch impact factors.");
            const data = await response.json();
            setImpactFactors(data);
          } catch (error) {
            console.error("Error fetching impact factors:", error);
            toast({
              title: "Error",
              description: "Failed to fetch impact factors.",
              variant: "destructive",
            });
          } finally {
            setLoadingImpactFactors(false);
          }
        };
        fetchImpactFactors();
      }
    }
  }, [training, trainingDoeId, accessToken, toast]);

  const handleGenerateImpactFactors = async () => {
    try {
      const payload = {
        trainingDoeId: training.trainingDoeId,
        processType: training.processType,
        aimType: training.aimType,
        gmoActivated: training.gmoActivated,
        gmoPurpose: training.gmoPurpose,
        microOrganismType: training.microOrganismType,
        microOrganismDescription: training.microOrganismDescription,
        bioProcessTargetType: training.bioProcessTargetType,
        moleculeType: training.moleculeType,
        productionVolume: training.productionVolume,
        bioreactorModel: training.bioreactorModel,
        processMode: training.processMode,
        trainingDoeName: training.trainingDoeName,
      };

      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate impact factors");
      }

      setIsGenerateModalOpen(false);
      toast({
        title: "Success",
        description:
          "Impact factors generation started. You will be notified when complete.",
      });
      refetch();
    } catch (error) {
      console.error("Error generating impact factors:", error);
      toast({
        title: "Error",
        description: "Failed to generate impact factors. Please try again.",
        variant: "destructive",
      });
      setIsSearchFailedModalOpen(true);
    }
  };

  const handleRetry = async () => {
    try {
      const payload = {
        trainingDoeId: training.trainingDoeId,
        processType: training.processType,
        aimType: training.aimType,
        gmoActivated: training.gmoActivated,
        gmoPurpose: training.gmoPurpose,
        microOrganismType: training.microOrganismType,
        microOrganismDescription: training.microOrganismDescription,
        bioProcessTargetType: training.bioProcessTargetType,
        moleculeType: training.moleculeType,
        productionVolume: training.productionVolume,
        bioreactorModel: training.bioreactorModel,
        processMode: training.processMode,
        trainingDoeName: training.trainingDoeName,
      };

      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retry impact factors generation");
      }

      setIsSearchFailedModalOpen(false);
      toast({
        title: "Success",
        description: "Retry initiated. You will be notified when complete.",
      });
      refetch();
    } catch (error) {
      console.error("Error retrying impact factors generation:", error);
      toast({
        title: "Error",
        description: "Failed to retry. Please try again later.",
        variant: "destructive",
      });
    }
  };

  const handlePDFsChange = (newPDFs) => {
    setPdfs(newPDFs);
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleStartDesign = async (experimentalDesignId) => {
    try {
      await startDesign({
        trainingDoeId,
        experimentalDesignId,
        measureFrequency: 1,
      }).unwrap();
      refetch();
    } catch (err) {
      toast({
        title: "Error",
        description: `Failed to start design: ${err.message}`,
        variant: "destructive",
      });
    }
  };

  const handleCreateExperimentalDesigns = async (selectedFactors) => {
    try {
      await createExperimentalDesigns({
        trainingDoeId,
        impactFactorsIntroduction: impactFactors.impactFactorsIntroduction,
        impactFactors: selectedFactors,
      }).unwrap();
      refetch();
      setIsImpactFactorsModalOpen(false);
      toast({
        title: "Success",
        description:
          "Experimental designs generation started. You will be notified when complete.",
      });
    } catch (err) {
      console.error("Error creating experimental designs:", err);
      toast({
        title: "Error",
        description: `Failed to create experimental designs: ${err.message}`,
        variant: "destructive",
      });
    }
  };

  const handleGenerateExperimentalDesigns = async () => {
    try {
      await generateExperimentalDesigns({
        trainingDoeId,
      }).unwrap();
      refetch();
      setIsGeneratingModalOpen(true);
      toast({
        title: "Success",
        description:
          "Experimental designs generation started. You will be notified when it's complete.",
      });
    } catch (err) {
      console.error("Error generating experimental designs:", err);
      toast({
        title: "Error",
        description: `Failed to generate experimental designs: ${err.message}`,
        variant: "destructive",
      });
      setIsGeneratingModalOpen(true);
    }
  };

  const handleRetryExperiments = async () => {
    try {
      await handleGenerateExperimentalDesigns();
      setIsExperimentsFailedModalOpen(false);
      toast({
        title: "Success",
        description: "Retrying experimental designs generation.",
      });
    } catch (error) {
      console.error("Error retrying experimental designs generation:", error);
      toast({
        title: "Error",
        description:
          "Failed to retry experimental designs generation. Please try again later.",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  if (isError || !training) {
    navigate("/trainings/not-found");
    return null;
  }

  const currentStateConfig = stateConfig[training.state] || stateConfig.SEARCH;
  const showOptimizationsTab = training.finished;

  return (
    <>
      {isGenerateModalOpen &&
        createModal(
          "Generating Training Impact Factors",
          ` Generating training impact factors. Please wait - this step is required before proceeding receive a confirmation email when this is ready. Please check both your inbox and spam folder to ensure you don't miss the notification.`
        )}

      {training.state === "GENERATE_EXPERIMENTS" &&
        createModal(
          "Generating Experimental Designs",
          `The experimental designs are being generated. Please wait. You will receive a confirmation email when this is ready. Please check both your inbox and spam folder to ensure you don't miss the notification.`
        )}

       {isSearchFailedModalOpen && 
        createErrorModal(
          "Training Impact Factor Generation Failed",
          "The training impact factor generation failed. Please try again to proceed with experimental designs.",
          isGeneratingImpactFactors,
          handleRetry
        )}

      {isExperimentsFailedModalOpen &&
       createErrorModal(
        "Experimental Designs Generation Failed",
        "The experimental designs generation process failed. Please try again.",
        isGeneratingExperimentalDesigns,
        handleRetryExperiments
      )}


      <div
        className="container mx-auto p-4 space-y-6"
        style={{ isolation: "isolate" }}
      >
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
            <div className="space-y-2">
              <CardTitle className="text-2xl font-bold">
                {training.trainingDoeName}
                <Badge
                  variant={training.finished ? "default" : "secondary"}
                  className="text-sm font-medium ml-4"
                >
                  {training.finished ? "Finished" : "In Progress"}
                </Badge>
              </CardTitle>
            </div>
            <div className="flex space-x-3">
              {training.state === "IMPACT_FACTOR" && (
                <Button
                  onClick={() => setIsImpactFactorsModalOpen(true)}
                  disabled={loadingImpactFactors || !impactFactors}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded"
                >
                  <Eye className="mr-2 h-5 w-5" />
                  {loadingImpactFactors
                    ? "Loading Impact Factors..."
                    : "View Impact Factors"}
                </Button>
              )}
              <CreateChatButton training={training} accessToken={accessToken}>
                Ask Me Anything
              </CreateChatButton>
            </div>
          </CardHeader>
          <CardContent className="pt-4">
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-3">
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">Start Date:</span>
                  <span>
                    {new Date(training.startDate).toLocaleDateString()}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Finish Date:
                  </span>
                  <span>
                    {training.finishDate
                      ? new Date(training.finishDate).toLocaleDateString()
                      : "Not finished"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Process Type:
                  </span>
                  <span>
                    {training.processTypeDescription || "Not specified"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">Aim Type:</span>
                  <span>{training.aimTypeDescription || "Not specified"}</span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    GMO Activated:
                  </span>
                  <span>{training.gmoActivated ? "Yes" : "No"}</span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    GMO Purpose:
                  </span>
                  <span>
                    {training.gmoPurposeDescription || "Not specified"}
                  </span>
                </p>
              </div>
              <div className="space-y-3">
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Microorganism:
                  </span>
                  <span>
                    {training.microOrganismDescription || "Not specified"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Bioprocess Target:
                  </span>
                  <span>
                    {training.bioProcessTargetTypeDescription ||
                      "Not specified"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Molecule Type:
                  </span>
                  <span>
                    {training.moleculeTypeDescription || "Not specified"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">Molecule:</span>
                  <span>{training.moleculeDescription || "Not specified"}</span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Production Volume:
                  </span>
                  <span>{training.productionVolume} L</span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Bioreactor Model:
                  </span>
                  <span>
                    {training.bioreactorModelDescription || "Not specified"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-foreground">
                    Process Mode:
                  </span>
                  <span>
                    {training.processModeDescription || "Not specified"}
                  </span>
                </p>
              </div>
            </div>
          </CardContent>
        </Card>

        {currentStateConfig.isViewOnly && (
          <Alert className="bg-primary text-background">
            <AlertCircle className="h-4 w-4 text-white" />
            <AlertTitle>View-Only Mode</AlertTitle>
            <AlertDescription>
              This training is in view-only mode. You can download elements but
              cannot make changes.
            </AlertDescription>
          </Alert>
        )}

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="bg-muted">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <TabsTrigger
                    value="experimental"
                    disabled={!currentStateConfig.canViewExperimentalDesigns}
                    className={`${
                      activeTab === "experimental"
                        ? "bg-background text-foreground"
                        : "text-muted-foreground"
                    } data-[state=active]:bg-background data-[state=active]:text-foreground`}
                  >
                    Experimental Designs
                  </TabsTrigger>
                </TooltipTrigger>
                {!currentStateConfig.canViewExperimentalDesigns && (
                  <TooltipContent>
                    {training.state === "SEARCH"
                      ? "This tab is disabled because no impact factors have been created yet."
                      : training.state === "GENERATE"
                      ? "Experimental designs are being generated."
                      : "This tab is not available in the current state."}
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
            {showOptimizationsTab && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <TabsTrigger
                      value="optimizations"
                      className={`${
                        activeTab === "optimizations"
                          ? "bg-background text-foreground"
                          : "text-muted-foreground"
                      } data-[state=active]:bg-background data-[state=active]:text-foreground`}
                    >
                      Optimizations
                    </TabsTrigger>
                  </TooltipTrigger>
                </Tooltip>
              </TooltipProvider>
            )}
            <TabsTrigger
              value="training"
              className={`${
                activeTab === "training"
                  ? "bg-background text-foreground"
                  : "text-muted-foreground"
              } data-[state=active]:bg-background data-[state=active]:text-foreground`}
            >
              Training Data
            </TabsTrigger>
          </TabsList>
          <TabsContent value="experimental">
            {training.experimentalDesigns &&
            training.experimentalDesigns.length > 0 ? (
              <ExperimentalDesigns
                experimentalDesigns={training.experimentalDesigns}
                onStartDesign={handleStartDesign}
                isStartingDesign={isStartingDesign}
                trainingDoeId={trainingDoeId}
                accessToken={accessToken}
                isViewOnly={currentStateConfig.isViewOnly}
                onUpdate={refetch}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-64 bg-card rounded-lg border-2 border-dashed border-border">
                <svg
                  className="w-12 h-12 text-muted-foreground mb-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
                <p className="text-lg font-medium text-foreground mb-2">
                  No experimental designs available
                </p>
                <p className="text-sm text-muted-foreground mb-4">
                  Generate experimental designs to get started
                </p>
                <Button
                  onClick={handleGenerateExperimentalDesigns}
                  disabled={isGeneratingExperimentalDesigns}
                  variant="default"
                  className="flex items-center space-x-2"
                >
                  {isGeneratingExperimentalDesigns ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>Generating...</span>
                    </>
                  ) : (
                    <>
                      <Beaker className="h-4 w-4 mr-2" />
                      <span>Generate Experimental Designs</span>
                    </>
                  )}
                </Button>
              </div>
            )}
          </TabsContent>
          {showOptimizationsTab && (
            <TabsContent value="optimizations">
              {training.optimizations && training.optimizations.length > 0 ? (
                <Optimizations
                  optimizations={training.optimizations}
                  isViewOnly={currentStateConfig.isViewOnly}
                  accessToken={accessToken}
                  trainingDoeId={trainingDoeId}
                />
              ) : (
                <p>No optimizations available.</p>
              )}
            </TabsContent>
          )}
          <TabsContent value="training">
            <Card>
              <CardContent>
                <ScrollArea className="h-[230px] mt-4">
                  {pdfs.length > 0 ? (
                    pdfs.map((pdf, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center mb-2"
                      >
                        <span>{pdf.name}</span>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleDownload(pdf.url, pdf.name)}
                        >
                          <Download className="h-4 w-4 mr-2" />
                          Download
                        </Button>
                      </div>
                    ))
                  ) : (
                    <p>No PDFs available.</p>
                  )}
                </ScrollArea>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        <Dialog
        open={isImpactFactorsModalOpen}
        onOpenChange={setIsImpactFactorsModalOpen}
      >
        <DialogContent className="sm:max-w-[425px] md:max-w-[700px]">
          <DialogHeader>
            <DialogTitle>Impact Factors</DialogTitle>
          </DialogHeader>
          {impactFactors ? (
            <ImpactFactorsManager
              data={impactFactors}
              trainingDoeId={trainingDoeId}
              onExperimentalDesignsGenerated={handleCreateExperimentalDesigns}
              isCreatingExperimentalDesigns={isCreatingExperimentalDesigns}
              isViewOnly={false}
              onCloseModal={() => setIsImpactFactorsModalOpen(false)}
            />
          ) : (
            <p>Loading impact factors...</p>
          )}
        </DialogContent>
      </Dialog>
      
      </div>
    </>
  );
}
