import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import localStorageUtils from "../../utils/localStorageUtils";

// Get access token from local storage
const accessToken = localStorageUtils.getLocalStorageItems("accessToken");

// Define the initial state for the auth slice
const initialState = { token: accessToken, user: {} };

// Decode the access token and set user info if available
if (accessToken) {
  initialState.user.userName = jwtDecode(accessToken).username;
  initialState.user.userId = jwtDecode(accessToken).user_id;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, refreshToken, userName } = action.payload;
      localStorageUtils.updateLocalStorageItems(
        ["accessToken", accessToken],
        ["refreshToken", refreshToken]
      );
      state.token = accessToken;
      state.refreshToken = refreshToken;
      state.user.userName = userName;
      state.user.userId = jwtDecode(accessToken).user_id;
    },
    logOut: (state, action) => {
      localStorageUtils.removeLocalStorageItems("accessToken", "refreshToken");
      state.token = null;
      state.refreshToken = null;
      state.user = {};
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

// Selector functions
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken;